export default class ReactRoutes {
    public static readonly ROOT = '/';
    public static readonly SELECT_USERTYPE = '/select';
    public static readonly CSGO_REGION = '/csgo-region';
    public static readonly WELCOME = '/welcome';
    public static readonly ALUMNI_WELCOME = '/alumni-welcome';
    public static readonly ALUMNI_CONTACT_HELP = '/alumni-help';
    public static readonly ALUMNI_CONTACT_SELECT = '/alumni-contact-select';
    public static readonly ALUMNI_FAQ = '/help';
    public static readonly ALUMNI_VERIFY_ACCOUNT_REQUEST = '/verify-account';
    public static readonly ALUMNI_CREATE_ACCOUNT = '/create-account';
    public static readonly ALUMNI_ERROR_SCREEN = '/alumni-error';
    public static readonly PRIVACY_POLICY = '/privacy-policy'
    public static readonly PREBOARDER_FORBIDDEN = '/preboarder-forbidden'
    public static readonly FORCED_AEA = '/forced-aea'
}
