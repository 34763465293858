import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TranslationKeys from 'src/constants/translation-keys';
import IconInformationCircle from 'src/images/icon_information_circle.svg';
import { recordEvent } from 'src/analytics/amplify'
import { RouterLink } from '../RouterLink';
import { Text } from '@amzn/stencil-react-components/text';
import {LinkTextDecorationOptions} from "@amzn/stencil-react-components/dist/submodules/link/interfaces";

export default function PrivacyFlyout() {
    const { t } = useTranslation();

    useEffect(() => {
        recordEvent({
            name: 'PrivacyFlyout.Render',
            immediate: true
        });
    }, []);

    return (
        <RouterLink textDecoration={LinkTextDecorationOptions.None}
                data-testid="privacy-flyout-button"
                to='/privacy-policy'
                className="privacy-flyout-container">
            <img src={IconInformationCircle} width="16" height="16" alt="" />
            <Text className="footer-text" data-testid="footer-text-privacy">
                {t(TranslationKeys.FOOTER.PRIVACY_POLICY)}
            </Text>
        </RouterLink>
    );
}
