import React, {useEffect, useRef, useState} from 'react';
import { Col, Container, View } from '@amzn/stencil-react-components/layout';
import { UserType } from 'src/constants/usertype';
import CsgoIcon from 'src/images/icon_csgo.svg';
import DaIcon from 'src/images/icon_da.svg';
import DspIcon from 'src/images/icon_dsp.svg';
import SruIcon from 'src/images/icon_sru.svg';
import PreboarderIcon from 'src/images/icon_preboarder.svg';
import UserSelectionTile from './UserSelectionTile';
import {
    showPbModal,
    showCsgoModal,
    showSruModal,
    showDspaModal,
    showDeconsolidatedLogin
} from 'src/constants/feature-gates';
import TranslationKeys from 'src/constants/translation-keys';
import { redirect } from 'react-router';
import ScreenNames from 'src/constants/screen-names';
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import ParameterNames from "src/constants/parameter-names";
import {clearRequestSource} from "src/redux/request-source-slice";

const UserSelectionTileList = () => {

    const dispatch = useAppDispatch();
    const source: string = useAppSelector(state => state.requestSource.value);

    const [daHref, setDaHref] = useState<string>('');
    useEffect(() => {
        const newHref = (source === ParameterNames.REQUEST_SOURCE_AUTHENTICATION_CONTROLLER ) ?
            "{{DaAuthenticationControllerUrl}}" : "{{DaEsspUrl}}";
        setDaHref(newHref);
        console.log('myQueryParam:', source);
        console.log('Updated href:', newHref);
    }, []);

    const handleDaOnClick = () => {
        dispatch(clearRequestSource());
        redirect(daHref);
    }

    const deconsolidatedLoginEnabled = showDeconsolidatedLogin();
    const tileBorderRadius = '5px'

    return (
        <Container>
            <Col>
                <ul className='ul-no-bullets'>
                    <li>
                        <View padding={{bottom: 10}}>
                            <UserSelectionTile
                                userType={UserType.DA}
                                screenName={ScreenNames.USERTYPE_SELECTION}
                                header={deconsolidatedLoginEnabled ? TranslationKeys.UI.USERTYPES.DA_HEADER : TranslationKeys.UI.USERTYPES.DA_HEADER2}
                                description={TranslationKeys.UI.USERTYPES.DA_DESCRIPTION}
                                borderRadius={tileBorderRadius}
                                iconSrc={DaIcon}
                                onClick={handleDaOnClick}
                                href={daHref}/>
                        </View>
                    </li>
                    {showDspaModal() &&
                        <li>
                            <View padding={{bottom: 10}}>
                                <UserSelectionTile
                                    userType={UserType.DSP}
                                    screenName={ScreenNames.USERTYPE_SELECTION}
                                    header={TranslationKeys.UI.USERTYPES.DSP_HEADER}
                                    description={TranslationKeys.UI.USERTYPES.DSP_DESCRIPTION}
                                    iconSrc={DspIcon}
                                    borderRadius={tileBorderRadius}
                                    onClick={() => redirect("{{DspaEsspUrl}}")}
                                    href={"{{DspaEsspUrl}}"}
                                    hidden={!showDspaModal()}
                                />
                            </View>
                        </li>
                    }
                    {showCsgoModal() &&
                        <li>
                            <View padding={{bottom: 10}}>
                                <UserSelectionTile
                                    userType={UserType.CSGO}
                                    screenName={ScreenNames.USERTYPE_SELECTION}
                                    header={deconsolidatedLoginEnabled ? TranslationKeys.UI.USERTYPES.CSGO_HEADER : TranslationKeys.UI.USERTYPES.CSGO_HEADER2}
                                    description={TranslationKeys.UI.USERTYPES.CSGO_DESCRIPTION}
                                    iconSrc={CsgoIcon}
                                    borderRadius={tileBorderRadius}
                                    onClick={() => redirect("/csgo-region")}
                                    href={"/csgo-region"}
                                    hidden={!showCsgoModal}
                                />
                            </View>
                        </li>
                    }
                    {showSruModal() &&
                        <li>
                            <View padding={{bottom: 10}}>
                                <UserSelectionTile
                                    userType={UserType.SRU}
                                    screenName={ScreenNames.USERTYPE_SELECTION}
                                    header={deconsolidatedLoginEnabled ? TranslationKeys.UI.USERTYPES.SRU_HEADER : TranslationKeys.UI.USERTYPES.SRU_HEADER2}
                                    description={TranslationKeys.UI.USERTYPES.SRU_DESCRIPTION}
                                    iconSrc={SruIcon}
                                    borderRadius={tileBorderRadius}
                                    href={"{{SruAuthenticationControllerUrl}}"}
                                    onClick={() => redirect("{{SruAuthenticationControllerUrl}}")}
                                    hidden={!showSruModal}
                                />
                            </View>
                        </li>
                    }
                    {showPbModal() &&
                        <li>
                            <View padding={{bottom: 10}}>
                                <UserSelectionTile
                                    userType={UserType.PREBOARDER}
                                    screenName={ScreenNames.USERTYPE_SELECTION}
                                    header={deconsolidatedLoginEnabled ? TranslationKeys.UI.USERTYPES.PREBOARDER_HEADER : TranslationKeys.UI.USERTYPES.PREBOARDER_HEADER2}
                                    description={TranslationKeys.UI.USERTYPES.PREBOARDER_DESCRIPTION}
                                    iconSrc={PreboarderIcon}
                                    borderRadius={tileBorderRadius}
                                    onClick={() => redirect("{{PbEsspUrl}}")}
                                    href={"{{PbEsspUrl}}"}
                                    hidden={!showPbModal}
                                />
                            </View>
                        </li>
                    }
                </ul>
            </Col>
        </Container>
    )
}

export default UserSelectionTileList;