import React, { useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../common/Logo';
import AssetsLogo from 'src/images/assets_logo.svg';
import { useTranslation } from "react-i18next";
import Footer from "src/components/common/Footer";
import { Button } from "@amzn/stencil-react-components/button";
import { Col, Spacer, View } from '@amzn/stencil-react-components/layout';
import { MessageBanner } from "@amzn/stencil-react-components/message-banner";
import { Text } from '@amzn/stencil-react-components/text';

const MIDWAY_MOBILE_LOGIN_URL = "https://atoz.amazon.work/m/login?type=corp";

const ForcedAeaScreen = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        document.title = 'Forced AEA - Amazon A to Z Login'
    }, []);
    useEffect(() => {
        document.body.classList.add(
            'login-page',
            'use-style-guide'
        );
    }, []);

    return (
        <>
            <div className='background-top' />
            <div className='centered-content' data-testid='forced-aea-content' style={{ zIndex: 0 }}>
                <Logo className='txt-cntr' src={AssetsLogo} />

                <View id="login-panel"
                      className={"content-box username-content"}
                      data-testid='forced-aea-view'
                      borderRadius={"5px"}
                      margin={{top: "10px", bottom: "10px"}} >
                    <Col gridGap="S200">
                        <Text id="login-form-header" fontSize={"T100"}> {t("forced_aea_page_title")} </Text>
                        <Text fontSize={"T100"}>
                            {t("forced_aea_page_description")}
                        </Text>
                        <MessageBanner
                            dismissButtonAltText="Dismiss"
                            hasRoundedCorners={true}
                        >
                            {t("forced_aea_page_update_prompt")}
                        </MessageBanner>
                        <Spacer height={5}/>
                        <View>
                            <Button id="login-form-login-btn" type="submit" className="btn btn-primary full-width" onClick={() => window.location.href = MIDWAY_MOBILE_LOGIN_URL}>
                                {t("forced_aea_page_button")}
                            </Button>
                        </View>
                    </Col>
                </View>
                <Footer />
            </div>
        </>
    )
}

export default ForcedAeaScreen;