import React from 'react'
import { useTranslation } from "react-i18next";
import { UserType } from "src/constants/usertype";
import RightChevron from 'src/images/icon_rightchevron.svg';
import RightChevron2 from 'src/images/icon_rightchevron2.svg';
import LeftChevron from 'src/images/icon_leftchevron.svg';
import LeftChevron2 from 'src/images/icon_leftchevron2.svg';
import { LinkCard } from '@amzn/stencil-react-components/card';
import { recordEvent } from 'src/analytics/amplify';
import {showDeconsolidatedLogin} from "src/constants/feature-gates";

interface Props {
    userType: UserType;
    screenName: string;
    header: string;
    description: string;
    iconSrc: string;
    borderRadius?: string;
    href?: string;
    onClick?: () => any;
    hidden?: boolean;
}

const recordAndExecute = (userType: string, screenName: string, onClick?: () => any) => {
    recordEvent({
      name: `${screenName}.${userType}.Count`,
      immediate: true
    });
    onClick && onClick();
}

const UserSelectionTile = ({userType,
                            screenName,
                            header,
                            description,
                            iconSrc,
                            borderRadius,
                            href,
                            onClick,
                            hidden = false}: Props) => {
    const { t, i18n } = useTranslation();
    const deconsolidatedLoginEnabled = showDeconsolidatedLogin();
    const className = `content-box selection-tile-container ${hidden ? 'hidden' : ''} ${!deconsolidatedLoginEnabled ? 'custom-link-card' : ''}`;
    const userTypeString = userType.toLowerCase();
    const divId = `${userTypeString}-picker`;
    const textDivId = `${userTypeString}-login-text`;
    const rtl = i18n.dir() === 'rtl';

    return (
        <LinkCard data-testid={`${userTypeString}-tile`}
                  id={divId} 
                  className={className}
                  height={deconsolidatedLoginEnabled ? 'auto' : 58}
                  style={{
                      boxShadow: '0px 1px 10px 0px rgba(35, 47, 62, 0.2)',
                      borderRadius: borderRadius ? borderRadius : '0',
                      border: !deconsolidatedLoginEnabled ? 'none' : undefined,
                  }}
                  padding={{top: '16px', bottom: '16px', left: '12px', right: '12px'}}
                  onClick={() => {recordAndExecute(userType.toUpperCase(), screenName, onClick)}}
                  href={href}>
            <img className={`icon${rtl ? "-rtl" : ""}`} src={iconSrc} alt="" aria-hidden={true} />
            <div id={textDivId}>
                <div className='tile-header'>{t(header)}</div>
                {deconsolidatedLoginEnabled && <div className='tile-description'>{t(description)}</div>}
            </div>
            {showDeconsolidatedLogin() ? (
                <img className="chevron" src={rtl ? LeftChevron : RightChevron} alt="" aria-hidden={true}/>
            ) : (
                <img className="chevron" src={rtl ? LeftChevron2 : RightChevron2} alt="" aria-hidden={true}/>
            )}
        </LinkCard>
    )
}

export default UserSelectionTile;