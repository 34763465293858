import React, { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import { Container, GridContainer, GridItem, View } from '@amzn/stencil-react-components/layout';
import defaultTheme from '@amzn/stencil-react-theme-default';
import { useTranslation } from "react-i18next";
import SelectionContainer from 'src/components/common/SelectionContainer';
import Header from 'src/components/usertypes/Header';
import Footer from 'src/components/common/Footer';
import CSGORegionSelectionTileList from './CSGORegionSelectionTileList';
import TranslationKeys from 'src/constants/translation-keys';
import ScreenNames from 'src/constants/screen-names';
import useRecordScreenVisit from 'src/hooks/useRecordScreenVisit';
import {showDeconsolidatedLogin} from "src/constants/feature-gates";

const CSGORegionSelectionScreen = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        document.title = 'CSGO Region Options - Amazon A to Z Login'
    }, []);
    
    useRecordScreenVisit(ScreenNames.CSGO_REGION_SELECTION);
    const deconsolidatedLoginEnabled = showDeconsolidatedLogin();

    return (
        <StencilProvider
            theme={{
                ...defaultTheme,
                constants: {
                    ...defaultTheme.constants,
                    maxWidth: '768px'
                }
            }}>
            <Container className='deconsolidated-container'
                       data-testid='csgo-selection-container' >
                <GridContainer>
                    <GridItem lg={12} md={8} sm={4}>
                        <View flex={1}>
                            <Header backButtonOnClick={() => navigate(deconsolidatedLoginEnabled ? "/select" : "/")}/>
                        </View>
                    </GridItem>
                    <GridItem lg={2} md={1} sm={1} />
                    <GridItem lg={8} md={8} sm={4}>
                        <View flex={1} padding={{left: 20, right: 20}}>
                            <SelectionContainer 
                                    screenName={ScreenNames.CSGO_REGION_SELECTION}
                                    selectionTitle={t(TranslationKeys.UI.CSGO.CSGO_REGION_SELECTION_TITLE)}
                                    hideNotSure={true}>
                                <CSGORegionSelectionTileList />
                            </SelectionContainer>
                        </View>
                    </GridItem>
                    <GridItem lg={2} md={1} sm={1} />
                    <GridItem lg={12} md={8} sm={4}>
                        <View flex={1}>
                            <Footer />
                        </View>
                    </GridItem>
                </GridContainer>
            </Container>
        </StencilProvider>
    )
}

export default CSGORegionSelectionScreen;