import React, { useLayoutEffect } from 'react';
import { View } from '@amzn/stencil-react-components/layout';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Footer from 'src/components/common/Footer';
import { UserType } from 'src/constants/usertype';
import Corp from 'src/images/icon_corp.svg';
import Other from 'src/images/icon_other.svg';
import UserSelectionTile from './usertypes/UserSelectionTile';
import TranslationKeys from 'src/constants/translation-keys';
import LoginForm from './LoginForm';
import Logo from './common/Logo';
import { showCorpModal, showSruModal, isPhaseTwo, isPhaseThree } from 'src/constants/feature-gates';
import AssetsLogo from 'src/images/assets_logo.svg';
import HalfVan from 'src/images/half_van_3.svg';
import { redirect } from 'react-router';
import ScreenNames from 'src/constants/screen-names';
import useRecordScreenVisit from 'src/hooks/useRecordScreenVisit';
import {useAppDispatch} from "src/redux/hooks";
import {setRequestSource} from "src/redux/request-source-slice";


const DeconsolidatedLoginScreen = () => {
    const { t } = useTranslation();

    let userpickerButtonText = t(TranslationKeys.UI.USERTYPES.OTHER_USERTYPE_HEADER_PHASE1)
    if (isPhaseThree()) {
        userpickerButtonText = t(TranslationKeys.UI.USERTYPES.OTHER_USERTYPE_HEADER_DA_CSGO_SRU_PB)
    } else if (isPhaseTwo()) {
        if (showSruModal()) {
            userpickerButtonText = t(TranslationKeys.UI.USERTYPES.OTHER_USERTYPE_HEADER_DA_CSGO_SRU)
        } else {
            userpickerButtonText = t(TranslationKeys.UI.USERTYPES.OTHER_USERTYPE_HEADER_DA_CSGO)
        }
    }
    useLayoutEffect(() => {
        document.title = 'Amazon A to Z Login'
    }, []);
    useEffect(() => {
        document.body.classList.add(
            'login-page',
            'use-style-guide'
        );
        document.body.style.backgroundColor = '#e3eaed';
    }, []);

    useRecordScreenVisit(ScreenNames.DECONSOLIDATED_LOGIN);

    const dispatch = useAppDispatch();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const sourceParam = params.get('source');
        if (sourceParam) {
            dispatch(setRequestSource(sourceParam));
        }
    }, []);

    const tileBorderRadius = '5px'
    
    return (
        <>
            <div className='background-top' />
            <div className='centered-content' data-testid='deconsolidated-login-content' style={{ zIndex: 0, width: '390px' }}>
                <Logo className='txt-cntr' src={AssetsLogo} />
                <LoginForm screenName={ScreenNames.DECONSOLIDATED_LOGIN}/>

                {showCorpModal() && 
                    <View padding={{ top: 16, bottom: 20 }}>
                        <UserSelectionTile 
                            userType={UserType.CORP}
                            screenName={ScreenNames.DECONSOLIDATED_LOGIN}
                            header={TranslationKeys.UI.USERTYPES.CORPORATE_HEADER}
                            description={TranslationKeys.UI.USERTYPES.CORPORATE_DESCRIPTION}
                            borderRadius={tileBorderRadius}
                            iconSrc={Corp}
                            href={"{{CorpEsspUrl}}"}
                        />
                    </View>
                }
                {(isPhaseTwo() || isPhaseThree()) &&
                    <View padding={{ bottom: 32 }}>
                        <UserSelectionTile
                            userType={UserType.OTHER}
                            screenName={ScreenNames.DECONSOLIDATED_LOGIN}
                            header={TranslationKeys.UI.USERTYPES.OTHER_USERTYPE_BUTTON_TITLE}
                            description={userpickerButtonText}
                            borderRadius={tileBorderRadius}
                            iconSrc={Other}
                            onClick={() => redirect("/select")}
                            href='/select' />
                    </View>
                }
                {!isPhaseTwo() &&
                    <View padding={{ bottom: 32 }}>
                        <UserSelectionTile
                            userType={UserType.DA}
                            screenName={ScreenNames.DECONSOLIDATED_LOGIN}
                            header={TranslationKeys.UI.USERTYPES.DA_HEADER}
                            description={TranslationKeys.UI.USERTYPES.DA_DESCRIPTION}
                            borderRadius={tileBorderRadius}
                            iconSrc={HalfVan}
                            onClick={() => redirect("{{DaEsspUrl}}")}
                            href={"{{DaEsspUrl}}"} />
                    </View>
                }
                <Footer />
            </div>
        </>
    )
}

export default DeconsolidatedLoginScreen;