import React, {useRef, useState} from 'react'
import {useTranslation} from "react-i18next";
import CookieHelper from "src/helpers/cookieHelper";
import Cookies from "src/constants/cookies";
import {getUrlParameter} from 'src/js/util';
import {View} from '@amzn/stencil-react-components/layout';
import {Button, ButtonVariant} from '@amzn/stencil-react-components/button';
import {H1, Label} from '@amzn/stencil-react-components/text';
import {Input, InputWrapper} from '@amzn/stencil-react-components/input';
import TranslationKeys from 'src/constants/translation-keys';
import {useAppDispatch, useAppSelector} from "src/redux/hooks";
import ParameterNames from "src/constants/parameter-names";
import {clearRequestSource} from "src/redux/request-source-slice";
import GetHelpFlyout from "src/components/common/GetHelpFlyout";
import {recordEvent} from "src/analytics/amplify";
import {showDeconsolidatedLogin} from "src/constants/feature-gates";
import {TooltipAlignment} from "@amzn/stencil-react-components/tooltip";

interface Props {
    screenName: string;
}

const LoginForm = ({screenName}: Props) => {
    const {t} = useTranslation();
    const [login, setLogin] = useState('');
    const [isLoginInvalid, setIsLoginInvalid] = useState(false);

    const formRef = useRef<HTMLFormElement | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const source: string = useAppSelector(state => state.requestSource.value);

    const aaAuthenticationControllerUrl: string =
        (source === ParameterNames.REQUEST_SOURCE_AUTHENTICATION_CONTROLLER) ?
            "{{AaAuthenticationControllerUrl}}" : "{{AaEsspUrl}}";
    const deconsolidatedLoginEnabled = showDeconsolidatedLogin();

    const setLoginHintCookie = () => {
        CookieHelper.createCookie(Cookies.LOGIN_HINT, login, 30, '/', CookieHelper.resolveDomainStringForCookie());
    };

    const getProviderId = () => {
        return getUrlParameter('providerId',
            aaAuthenticationControllerUrl.split('?')[1]);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!deconsolidatedLoginEnabled && login.trim() === '') {
            setIsLoginInvalid(true);
            inputRef.current?.focus();
            return;
        }

        formRef.current!.action = aaAuthenticationControllerUrl;
        setLoginHintCookie();
        dispatch(clearRequestSource());
        formRef.current!.submit();
    }

    function handleFlyoutClick(screenName: string, flyoutType: string) {
        recordEvent({
            name: `${screenName}.${flyoutType}.Click`,
            immediate: true
        });
    }

    const handleInputChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setLogin(e.target.value);
        if (e.target.value !== '') {
            setIsLoginInvalid(false);
        }
    }

    return (
        <View id="login-panel"
              className={"content-box username-content"}
              data-testid='login-form-view'
              borderRadius={"5px"}
              style={{
                  borderBottom: deconsolidatedLoginEnabled ? '1px solid #ddd' : 'none',
                  padding: deconsolidatedLoginEnabled ? '20px' : '0px'
              }}
              margin={{top: "10px", bottom: "10px"}}>
            <View id='subtle-picker'>
                {deconsolidatedLoginEnabled ? (
                    <>
                        <h1 id="login-form-header">
                            {t(TranslationKeys.UI.LOGIN_FORMS.LOGIN_HEADER)}
                        </h1>
                        <p id="associate-login-text" className='login-form-context'>
                            {t(TranslationKeys.UI.LOGIN_FORMS.ASSOCIATE_LOGIN_TEXT1)}
                            <br/>
                            {t(TranslationKeys.UI.LOGIN_FORMS.ASSOCIATE_LOGIN_TEXT2)}
                        </p>
                    </>
                ) : (
                    <H1 data-test-id="login-form-header"
                        style={{color: "neutral90", fontSize: "24px", fontWeight: "bold"}}>
                        {t(TranslationKeys.UI.LOGIN_FORMS.LOGIN_HEADER2)}
                    </H1>
                )}
                <form ref={formRef} data-testid='aa-login-form' id="aa-login-form" method="GET" onSubmit={handleSubmit}>
                    <input type="hidden" name="loginType" value="AA"/>
                    <input id="providerId-hidden-field" type="hidden" name="providerId" value={getProviderId()}/>
                    {deconsolidatedLoginEnabled ? (
                        <>
                            <View className="form-group">
                                <Label id="login-label" htmlFor="associate-login-input">
                                    <p id="amazon-login-text"
                                       className="login-form-label"> {t(TranslationKeys.UI.LOGIN_FORMS.LOGIN_LABEL)} </p>
                                </Label>
                                <Input id="associate-login-input" type="text" className="form-control" required={true}
                                       name="login" autoFocus maxLength={10}
                                       placeholder={t(TranslationKeys.UI.LOGIN_FORMS.ASSOCIATE_LOGIN_INPUT_HINT)}
                                       value={login} onChange={(e) => {
                                    setLogin(e.target.value)
                                }} aria-labelledby='login-label'/>
                            </View>
                            <View className="form-buttons">
                                <Button
                                    id="login-form-login-btn"
                                    type="submit"
                                    className="btn btn-primary full-width"
                                >
                                    {t(TranslationKeys.UI.BUTTONS.LOGIN)}
                                </Button>
                            </View>
                        </>
                    ) : (
                        <>
                            <InputWrapper
                                id="login-label"
                                labelText={t(TranslationKeys.UI.LOGIN_FORMS.LOGIN_LABEL)}
                                tooltipAlignment={TooltipAlignment.Left}
                                value={login}
                                required
                                footer= {isLoginInvalid ? t(TranslationKeys.UI.LOGIN_FORMS.REQUIRED_FIELD_ERROR_MESSAGE) : undefined}
                                error={isLoginInvalid}>
                                {inputProps =>
                                    <Input {...inputProps} ref={inputRef} id="associate-login-input" type="text"
                                           className="form-control" aria-required="true"
                                           name="login" autoFocus maxLength={10}
                                           onChange={handleInputChange}
                                           placeholder={t(TranslationKeys.UI.LOGIN_FORMS.ASSOCIATE_LOGIN_INPUT_HINT)}
                                           aria-labelledby='login-label'/>
                                }
                            </InputWrapper>

                            <View onClick={() => handleFlyoutClick(screenName, 'ForgotAlias')}
                                  style={{ paddingTop: '12px' }}>
                                <GetHelpFlyout
                                    buttonText={t(TranslationKeys.UI.BUTTONS.FORGOT_ALIAS)}
                                    hideButtonIcon={true}
                                />
                            </View>
                            <View className="form-buttons">
                                <Button
                                    id="login-form-login-btn"
                                    type="submit"
                                    variant={ButtonVariant.Primary}
                                    className="btn full-width focus-visible"
                                    style={{borderRadius: '25px'}}
                                >
                                    {t(TranslationKeys.UI.BUTTONS.CONTINUE)}
                                </Button>
                            </View>
                        </>
                    )}
                </form>
            </View>
        </View>
    );
}

export default LoginForm;