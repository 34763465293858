import React from 'react';
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { IconArrowLeft } from '@amzn/stencil-react-icons';
import TranslationKeys from 'src/constants/translation-keys';

interface Props {
    backButtonOnClick: () => any;
}

const UserTypeSelectionHeader = ({backButtonOnClick} : Props) => {
    const {t} = useTranslation();
    return (
        <Button
            data-testid='usertypes-header-back-button'
            icon={<IconArrowLeft aria-hidden={true} />}
            variant={ButtonVariant.Tertiary}
            onClick={backButtonOnClick}>
            {t(TranslationKeys.UI.BUTTONS.BACK)}
        </Button>
    );
}

export default UserTypeSelectionHeader;