import React, {useEffect, useState} from 'react';
import {Button, ButtonVariant} from '@amzn/stencil-react-components/button';
import {FlyoutContent, WithFlyout} from '@amzn/stencil-react-components/flyout';
import {InputWrapper, LabelPosition} from '@amzn/stencil-react-components/input';
import {Radio} from '@amzn/stencil-react-components/radio';
import {Col} from '@amzn/stencil-react-components/layout';
import {Text} from '@amzn/stencil-react-components/text';
import i18n from 'i18next';
import {useTranslation} from 'react-i18next';
import TranslationKeys from 'src/constants/translation-keys';
import IconGlobeState from 'src/images/icon_globe_state.svg';
import IconGlobeStateBlue from 'src/images/icon_globe_state_blue.svg';
import I18nHelper from 'src/helpers/i18nHelper';
import {UseCase} from 'src/components/common/UseCase';
import {showDeconsolidatedLogin} from "src/constants/feature-gates";

const i18nConfig = require('../../../configuration/i18nConfig');

type Props = {
    useCase?: UseCase;
}

export default function LanguageSelectFlyout({useCase} : Props) {
    const { t } = useTranslation();

    const [selectedLang, setSelectedLang] = useState<string | undefined>(undefined);
    const [userTriggeredChangeLanguage, setUserTriggeredChangeLanguage] = useState(false);

    function changeLanguage(value?: string) {
        i18n.changeLanguage( i18nConfig.langMap.get(value), () => {} ).then();
    }

    useEffect(() => {
        if (userTriggeredChangeLanguage && selectedLang) {
            changeLanguage(selectedLang);
        }
        // reset
        setUserTriggeredChangeLanguage(false);
        setSelectedLang(undefined);
    }, [userTriggeredChangeLanguage]);

    function renderLanguageList() {
        const selectedLangIndex = I18nHelper.getIndexOfSelectedLanguage();
        return (
            <Col gridGap="S300">
                <Text fontSize="T500">
                    {t(TranslationKeys.FOOTER.LANGUAGE.CHANGE_LANGUAGE_HEADER)}
                </Text>
                { i18nConfig.langArray.map((val: string, index: number) => {
                    return <InputWrapper
                        id={"radio-trailing" + index}
                        labelText={val}
                        key={"radio-wrapper" + index}
                        labelPosition={LabelPosition.Trailing}>
                        {(inputProps) => <Radio key={"radio" + index}
                                              name="radio"
                                              data-testid={ "languageSelectionRadio" + index }
                                              defaultChecked={ selectedLangIndex === index }
                                              onClick={ () => setSelectedLang(val) }
                                              {...inputProps} />}
                    </InputWrapper>
                }) }
            </Col>
        )
    }

    const renderFlyout = ({ close }: { close: () => void }) => (
        <FlyoutContent
            titleText={t(TranslationKeys.FOOTER.LANGUAGE.CHOOSE_LANGUAGE)}
            onCloseButtonClick={close}
            buttons={[
                <Button data-testid="language-flyout-close-button"
                        onClick={
                            () => {
                                setUserTriggeredChangeLanguage(true);
                                close();
                            }
                        }
                        key={'close-button-key'}
                        variant={ButtonVariant.Primary}>
                     {t(TranslationKeys.FOOTER.LANGUAGE.CHANGE_LANGUAGE)}
                </Button>
            ]}>
            { renderLanguageList() }
        </FlyoutContent>
    );

    const globeIcon = useCase === UseCase.ALUMNI ? IconGlobeState : IconGlobeStateBlue;
    const textColor = useCase === UseCase.ALUMNI ? "" : "primary70";
    const textClassName = useCase === UseCase.ALUMNI ? "footer-text" : "common-footer-text"

    const deconsolidatedLoginEnabled = showDeconsolidatedLogin();
    return (
        <WithFlyout renderFlyout={renderFlyout} onClose= {() => setUserTriggeredChangeLanguage(false)}>
            {({open}) => (
                <Button variant={deconsolidatedLoginEnabled ? ButtonVariant.Tertiary : ButtonVariant.TertiaryInline}
                        data-testid="language-flyout-button"
                        onClick={open}
                        className={!deconsolidatedLoginEnabled ? 'focus-visible' : ''}
                        aria-label={useCase === UseCase.ALUMNI ? t(TranslationKeys.UI.ALUMNI.LANGUAGE_FOOTER) : t(TranslationKeys.FOOTER.LANGUAGE.CHANGE_LANGUAGE)}>
                        <img src={globeIcon} width="14.4" height="14.4" alt="" />
                    <Text className={textClassName} data-testid="footer-text-language" color={textColor} aria-hidden={true}>
                        {useCase === UseCase.ALUMNI ? t(TranslationKeys.UI.ALUMNI.LANGUAGE_FOOTER) : t(TranslationKeys.FOOTER.LANGUAGE.CHANGE_LANGUAGE)}
                    </Text>
                </Button>
            )}
        </WithFlyout>
    );
}
