import React from 'react';
import { Container, GridContainer, GridItem, Hr, Row, View } from '@amzn/stencil-react-components/layout';
import { Label } from '@amzn/stencil-react-components/text';
import LanguageSelectFlyout from 'src/components/common/LanguageSelectFlyout';
import { recordEvent } from 'src/analytics/amplify'
import GetHelpFlyout from './GetHelpFlyout';
import TranslationKeys from 'src/constants/translation-keys';
import { useTranslation } from "react-i18next";
import {showDeconsolidatedLogin} from "src/constants/feature-gates";

export default function Footer() {

  const { t } = useTranslation();

  function handleFlyoutClick(FlyoutType: string) {
    recordEvent({
      name: `Footer.${FlyoutType}Flyout.Click`,
      immediate: true
    });
  }

  const deconsolidatedLoginEnabled = showDeconsolidatedLogin();

  return (
    <Container data-testid='common-footer'>
      <GridContainer >
        <GridItem lg={2} md={1} sm={1}/>          
        <GridItem lg={8} md={8} sm={4}>
          {deconsolidatedLoginEnabled && (
              <View flex={1}>
                <Hr/>
              </View>)}
        </GridItem>
        <GridItem lg={2} md={1} sm={1}/>          
      </GridContainer>
        <Row alignItems="center" justifyContent="center">
          <LanguageSelectFlyout />
          <Label textAlign="center" color='primary70' aria-hidden={true}
                 style={!deconsolidatedLoginEnabled ? {marginLeft: '16px', marginRight: '16px'} : undefined}>|</Label>
          <View onClick={() => handleFlyoutClick('GetHelp')}>
            <GetHelpFlyout buttonText={t(TranslationKeys.FOOTER.GET_HELP)} />
          </View>
        </Row>
    </Container>
  );
}
